$accent-1: #35bd49 !default;
$accent-2: #353844 !default;
$primary: #2284fe !default;
$secondary: #78baf8 !default;
$success: $accent-1 !default;
$info: #5a7ec5 !default;
$warning: #f0a249 !default;
$danger: #fc2344 !default;
$light: #e5e5e5 !default;
$dark: #3b3b3b !default;

$theme-colors-extended: () !default;
$theme-colors-extended: map-merge((
  primary: (
    color: $primary,
    border: #4c8afe,
    gstart: #2284fe,
    gstop: $primary,
    highlight: #b3d1fe,
    contrast: #fff
  ),
  secondary: (
    color: $secondary,
    border: #9fd3f8,
    gstart: #78baf8,
    gstop: #6297f8,
    highlight: mix($secondary, #fff, 25%),
    contrast: #fff
  ),
  success: (
    color: $success,
    border: #239834,
    gstart: #34bc48,
    gstop: #2caa3f,
    highlight: mix($success, #fff, 25%),
    contrast: #fff
  ),
  info: (
    color: $info,
    border: #3e64c5,
    gstart: #5a7ec5,
    gstop: #5261c5,
    highlight: mix($info, #fff, 25%),
    contrast: #fff
  ),
  warning: (
    color: $warning,
    border: #f07838,
    gstart: #f0824d,
    gstop: #f0a249,
    highlight: mix($warning, #fff, 25%),
    contrast: #fff
  ),
  danger: (
    color: $danger,
    border: #fc0d46,
    gstart: #fc2344,
    gstop: #fc2830,
    highlight: mix($danger, #fff, 25%),
    contrast: #fff
  ),
  dark: (
    color: $dark,
    border: #262626,
    gstart: #313131,
    gstop: #323232,
    highlight: mix($dark, #fff, 25%),
    contrast: #fff
  ),
  light: (
    color: $light,
    border: #bdbadf,
    gstart: #e5e5e5,
    gstop: #f8f8f8,
    highlight: mix($light, #000, 25%),
    contrast: #232323
  ),
  accent-1: (
    color: $accent-1,
    border: #239834,
    gstart: #34bc48,
    gstop: #2caa3f,
    highlight: mix($accent-1, #fff, 25%),
    contrast: #fff
  ),
  accent-2: (
    color: $accent-2,
    border: #4c505c,
    gstart: #353844,
    gstop: #444860,
    highlight: #e6e5fc,
    contrast: #d8d9dd
  )
), $theme-colors-extended);

@import 'charming-pro';
